import { useMutation } from "@apollo/client";
import React, { useCallback } from "react";

import { useTranslation } from "next-i18next";
import { DELETE_VISIT } from "../../../../../../graphql/mutations/mutationEvent";

// import {
//   // GET_VISITS,
//   GET_CLIENT_EVENTS,
//   // VISIT_SET_FRAGMENT,
// } from "../../../../../../graphql/queries/queriesEvent";
// import { INITIAL_PAGE_SIZE } from "../../../routePages/ScheduleSection/useEventsByHall";

const CancelButton = ({
  id,
  eventId,
  visitId,

  title,
  className,
  setCancelTrainingData = Function.prototype,
  familyId,
  // isProfile,
  // paid,
  queryOptions,
  setEvents,
}) => {
  const [t] = useTranslation();

  // console.log(id, eventId, visitId, familyId);

  const [deleteVisit, { client }] = useMutation(DELETE_VISIT, {
    variables: { visitId },

    // onQueryUpdated: (query) => {
    //   console.log("onQUery");
    //   const { variables, ...rest } = query?.options?.variables ?? {};

    //   if (rest.activeBlockOfContent !== "list") return true;

    //   const serializedState = query.queryInfo.cache.extract();

    //   const itemNumber = Object.values(serializedState)
    //     .filter((item) => item.__typename === "ClientEventType")
    //     .sort(({ id: a, id: b }) => a - b)
    //     .findIndex((i) => i.id === id);

    //   if (itemNumber > -1)
    //     rest.page = Math.ceil((itemNumber + 1) / INITIAL_PAGE_SIZE);

    //   query.refetch(rest);
    //   return false;
    // },

    update: (
      cache,
      {
        data: {
          deleteVisit: { ok },
        },
      }
    ) => {
      if (!ok || !id) return;

      if (!familyId) {
        //! if (queryOptions.page) {
        // const serializedState = cache.extract();

        // const itemNumber = Object.values(serializedState)
        //   .filter((item) => item.__typename === "ClientEventType")
        //   .sort(({ id: a, id: b }) => a - b)
        //   .findIndex((i) => i.id === id);

        // const page =
        //   itemNumber > -1
        //     ? Math.ceil((itemNumber + 1) / INITIAL_PAGE_SIZE)
        //     : 1;

        // const variables = { ...queryOptions, page };

        // const normalizedId = cache.identify({
        //   id: visitId,
        //   __typename: "VisitDetailType",
        // });

        // cache.evict({ id: normalizedId });
        // cache.gc();

        // const data = cache.readFragment({
        //   id: `VisitDetailType:${visitId}`,
        //   fragment: VISIT_SET_FRAGMENT,
        //   // fragmentName: "visitSetFragment",
        // });

        // cache.writeFragment({
        //   id: `VisitDetailType:${visitId}`,
        //   fragment: VISIT_SET_FRAGMENT,
        //   data: null,
        // });

        // const { events } = cache.readQuery({
        //   query: GET_CLIENT_EVENTS,
        //   variables,
        // });

        // cache.evict({
        //   fieldName: "event",
        //   broadcast: false,
        // });

        // cache.updateQuery(
        //   { query: GET_CLIENT_EVENTS, variables },
        //   (data) => ({
        //     events: data.events.map((event) => {
        //       const { id: eventId } = event;
        //       return eventId !== id
        //         ? event
        //         : {
        //             ...event,
        //             visitSet: event.visitSet.filter(({ id }) => {
        //               // console.log(event.visitSet, "event.visitSet", visitId);
        //               return id !== visitId;
        //             }),
        //           };
        //     }),
        //   })
        // );

        //! cache.modify({
        // fields: {

        // clientEvents(list, { readField }) {
        //   console.log("events", variables, list);
        //   return list.filter((n) => readField("id", n) !== id);
        //   // return list;
        // },
        // },
        // });

        // cache.writeQuery({
        //   query: GET_CLIENT_EVENTS,
        //   variables,
        //   data: {
        //     events: events.map((event) => {
        //       const { id: eventId } = event;
        //       return eventId !== id
        //         ? event
        //         : {
        //             ...event,
        //             visitSet: event.visitSet.filter(({ id }) => {
        //               console.log(event.visitSet, "event.visitSet", visitId);
        //               return id !== visitId;
        //             }),
        //           };
        //     }),
        //   },
        // });

        //   return;
        //! }

        //! last
        const cacheId = cache.identify({ id, __typename: "ClientEventType" });

        cache.modify({
          id: cacheId,

          fields: {
            visitSet(existingCommentRefs, { readField }) {
              return existingCommentRefs.filter(
                (commentRef) => visitId !== readField("id", commentRef)
              );
            },
          },
        });

        return;
      }

      // cache.modify({
      //   fields: {
      //     visits(list, { readField }) {
      //       return list.filter((i) => readField("id", i) !== visitId);
      //     },
      //   },
      // });

      const normalizedId = cache.identify({
        id: visitId,
        __typename: "VisitType",
      });

      cache.evict({ id: normalizedId });
      cache.gc();
    },
    fetchPolicy: "no-cache",
  });

  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      const refetchQueries = eventId
        ? ["ClientEventDetail"]
        : // : !familyId
          // ? [{ query: GET_CLIENT_EVENTS, variables: { ...queryOptions, page } }]
          []; // isProfile ? ["Visits"] : ["ClientEvents"]

      deleteVisit({ ...(refetchQueries.length && { refetchQueries }) }).then(
        ({ data }) => {
          setCancelTrainingData({
            message: t("visit_was_canceled"),
            isSuccess: true,
          });

          if (!familyId && queryOptions) {
            const { deleteVisit: { event: { status } = {} } = {} } = data;
            setEvents((events) => {
              return events.map((event) => {
                const { id: eventId } = event;
                return eventId !== id
                  ? event
                  : {
                      ...event,
                      status,
                      activity: { ...event.activity },
                      zone: { ...event.zone },
                      instructors: event.instructors.map((i) => ({ ...i })),
                      visitSet: event.visitSet.filter(({ id }) => {
                        return id !== visitId;
                      }),
                    };
              });
            });
          }
        },
        ({ message, graphQLErrors }) => {
          if (graphQLErrors?.[0]?.result === 5) return;
          setCancelTrainingData({
            message,
            isSuccess: false,
          });
        }
      );
    },
    [eventId, visitId, client, queryOptions, familyId]
  );

  return (
    <>
      <button className={className} onClick={onClick}>
        {title}
      </button>
    </>
  );
};

export { CancelButton };
