import { gql } from "@apollo/client";

export const REQUEST_VISIT = gql`
  mutation requestVisit($eventId: Int!, $pilot: Boolean) {
    requestVisit(origin: 2, eventId: $eventId, pilot: $pilot) {
      visit {
        id
      }
    }
  }
`;

export const ADD_VISIT_OPTION = gql`
  mutation VisitAddOption($visitId: Int!, $options: JSONString!) {
    visitAddOption(visitId: $visitId, options: $options) {
      visit {
        id

        visitOptions {
          id
          optionId
          title
          price
          image
          description
          price
        }
      }
    }
  }
`;

export const DELETE_VISIT_OPTION = gql`
  mutation VisitRemoveOption($visitId: Int!, $options: JSONString!) {
    visitRemoveOption(visitId: $visitId, options: $options) {
      visit {
        id

        visitOptions {
          id
          optionId
          title
          price
          image
          description
          price
        }
      }
    }
  }
`;

export const REQUEST_VISIT_BY_FAMILY = gql`
  mutation RequestVisitMutation(
    $familyProfileId: Int
    $eventId: Int!
    $origin: Int!
    $options: JSONString
  ) {
    requestVisit(
      familyProfileId: $familyProfileId
      eventId: $eventId
      origin: $origin
      options: $options
    ) {
      visit {
        id
      }
    }
  }
`;

export const CREATE_GUEST_PROFILE = gql`
  mutation CreateGuestProfile($visitId: Int!) {
    createGuestVisit(visit: $visitId) {
      ok
    }
  }
`;

export const DELETE_VISIT = gql`
  mutation DeleteVisit($visitId: Int!) {
    deleteVisit(visit: $visitId) {
      ok
      event {
        id
        status
      }
    }
  }
`;

export const EVENT_CREATE_PAYMENT = gql`
  mutation visitCreatePayment(
    $eventId: Int!
    $nextUrl: String!
    $familyProfileId: Int
    $options: JSONString
  ) {
    onlinePayment: visitCreatePayment(
      eventId: $eventId
      familyProfileId: $familyProfileId
      origin: 2
      nextUrl: $nextUrl
      options: $options
    ) {
      result

      payment {
        action
        price
        priceOriginal
        priceCategory
        priceCategoryDetail
        comment
        clientCommission
        parameters
      }
    }
  }
`;

export const EVENT_UPDATE_PAYMENT = gql`
  mutation visitUpdatePayment($visitId: Int!, $nextUrl: String!) {
    onlinePayment: visitUpdatePayment(visitId: $visitId, nextUrl: $nextUrl) {
      result

      payment {
        action
        price
        priceOriginal
        priceCategory
        priceCategoryDetail
        comment
        clientCommission
        parameters
      }
    }
  }
`;
